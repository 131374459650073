import { createSlice } from '@reduxjs/toolkit'
import { checkFarmingStatus, startFarming } from 'store/farm/actions'
import { LOADING_STATUSES } from 'utils/constants'

const initialState = {
  isChecked: false,
  coinsPerSecond: null,
  coinsPerSession: null,
  farmedCoins: null,
  timeLeft: null,
  multiplier: 1,
  autoFarming: null,
  getFarmingStatus: LOADING_STATUSES.idle,
}

const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    setMultiplier: (state, { payload }) => {
      state.multiplier = payload || 1
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(checkFarmingStatus.pending, (state) => {
      state.getFarmingStatus = LOADING_STATUSES.pending
      state.isChecked = true
    })
    addCase(checkFarmingStatus.fulfilled, (state, { payload }) => {
      state.getFarmingStatus = LOADING_STATUSES.succeeded
      state.isChecked = true
      state.coinsPerSecond = payload.coinsPerSecond
      state.coinsPerSession = payload.coinsPerSession
      state.farmedCoins = payload.farmedCoins
      state.timeLeft = payload.timeLeft
      state.multiplier = payload.multiplier || 1
      state.autoFarming = payload.autoFarming
    })
    addCase(checkFarmingStatus.rejected, (state) => {
      state.getFarmingStatus = LOADING_STATUSES.failed
      state.isChecked = true
    })

    addCase(startFarming.pending, (state) => {
      state.getFarmingStatus = LOADING_STATUSES.pending
    })
    addCase(startFarming.fulfilled, (state, { payload }) => {
      state.getFarmingStatus = LOADING_STATUSES.succeeded
      state.coinsPerSecond = payload.coinsPerSecond
      state.coinsPerSession = payload.coinsPerSession
      state.farmedCoins = payload.farmedCoins
      state.timeLeft = payload.timeLeft
      state.autoFarming = payload.autoFarming
    })
    addCase(startFarming.rejected, (state) => {
      state.getFarmingStatus = LOADING_STATUSES.failed
    })
  },
})

export const farmReducer = farmSlice.reducer

export const farmActions = {
  checkFarmingStatus,
  startFarming,
  ...farmSlice.actions,
}
