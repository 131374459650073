import { Image } from 'assets/images'
import Button from 'common/Button'
import * as S from './styled'

function BuyStoreItemDialogContent({ selectedItem, buyItem }) {
  return (
    <S.StoreDialogWrapper>
      <S.StoreDialogHeader>
        <S.StoreDialogHeaderIcon
          src={`${process.env.REACT_APP_API_URL}/images/${selectedItem?.imagePath}`}
          alt='star'
        />
        <S.StoreDialogHeaderInfo>
          <S.StoreDialogItemTitle>{selectedItem?.name}</S.StoreDialogItemTitle>
          <S.StoreDialogItemPriceWrapper>
            <S.StarIcon src={Image.telegramStar} alt='star' />
            <S.StoreDialogItemPrice>
              {selectedItem?.price}
            </S.StoreDialogItemPrice>
          </S.StoreDialogItemPriceWrapper>
        </S.StoreDialogHeaderInfo>
      </S.StoreDialogHeader>
      <S.StoreDialogDescription>
        {selectedItem?.description}
      </S.StoreDialogDescription>
      <Button text='Buy' onClick={buyItem} />
    </S.StoreDialogWrapper>
  )
}

export default BuyStoreItemDialogContent
