import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const StoreDialogWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`

export const StoreDialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
`

export const StoreDialogHeaderIcon = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
`

export const StoreDialogHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const StoreDialogItemTitle = styled.p`
  font-size: 14px;
  color: ${COLORS.white};
`

export const StoreDialogItemPriceWrapper = styled.div`
  display: flex;
  padding: 4px 6px;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${COLORS.gray_3B};
`

export const StarIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const StoreDialogItemPrice = styled.p`
  font-size: 12px;
  color: ${COLORS.white};
`

export const StoreDialogDescription = styled.p`
  font-size: 12px;
  color: ${COLORS.gray};
  text-align: center;
  max-width: 322px;
`
