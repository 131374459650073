import React from 'react'
import * as S from './styled'

function FormInput({
  width,
  placeholder,
  disabled,
  value,
  onChange,
  ...settings
}) {
  return (
    <S.StyleInputWrapper width={width}>
      <S.StyledInput
        {...settings}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </S.StyleInputWrapper>
  )
}

export default FormInput
