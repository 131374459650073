import { Image } from 'assets/images'
import Button from 'common/Button'
import { GameCongratulationsDialog } from 'components/GameCongratulationsDialog'
import { GamesHeader } from 'components/GamesHeader'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gamesSelector } from 'store/games/selectors'
import { gamesActions } from 'store/games/slice'
import { COLORS, LOADING_STATUSES, ROUTES } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

export function QuizGame({ quiz }) {
  const [selectedOption, setSelectedOption] = useState('')
  const [openModal, setOpenModal] = useState(true)
  const { playQuizGameStatus } = useSelector(gamesSelector)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectOption = (option) => {
    if (playQuizGameStatus === LOADING_STATUSES.idle) {
      setSelectedOption((prev) => (prev === option ? '' : option))
    }
  }

  const play = () => {
    dispatch(gamesActions.playQuizGame({ answer: selectedOption }))
  }

  const getOptionBorderColor = (option) => {
    if (
      playQuizGameStatus === LOADING_STATUSES.succeeded &&
      quiz.question.capital === option
    ) {
      return COLORS.green
    }
    if (
      playQuizGameStatus === LOADING_STATUSES.succeeded &&
      option === selectedOption
    ) {
      return COLORS.red
    }
    if (selectedOption === option) {
      return COLORS.blue
    }
    return COLORS.gray_64
  }

  const getCheckButtonStatus = () => {
    return !selectedOption || playQuizGameStatus !== LOADING_STATUSES.idle
  }

  useEffect(() => {
    if (!quiz.canPlay) {
      navigate(ROUTES.games)
    }
  }, [])

  return (
    <S.QuizView>
      <GamesHeader
        image={Image.millionaireCircle}
        title='Who Wants to Be a Millionaire'
        subtitle={`Earn ${formatCoin(quiz.reward)} every day`}
        imageSize='88px'
      />
      <S.QuizWrapper>
        <S.QuizQuestionWrapper>
          <S.QuizQuestion>
            What is the capital of {quiz.question?.country}?
          </S.QuizQuestion>
        </S.QuizQuestionWrapper>
        <S.QuizOptionsWrapper>
          {quiz.cities?.map((city, index) => (
            <S.QuizOption
              key={city}
              onClick={() => selectOption(city)}
              borderColor={getOptionBorderColor(city)}
            >
              {String.fromCharCode(65 + index)}) {city}
            </S.QuizOption>
          ))}
        </S.QuizOptionsWrapper>
        <Button
          fontSize='14px'
          text='Check'
          maxWidth='100%'
          isDisabled={getCheckButtonStatus()}
          onClick={play}
        />
      </S.QuizWrapper>
      <GameCongratulationsDialog
        closeDialog={() => setOpenModal(false)}
        reward={quiz.reward}
        isOpen={playQuizGameStatus === LOADING_STATUSES.succeeded && openModal}
        success={selectedOption === quiz.question?.capital}
      />
    </S.QuizView>
  )
}
