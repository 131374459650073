import React from 'react'

export function BcgamesIcon() {
  return (
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'>
      <path
        d='M0 0 C2.54232342 -0.08152344 5.08199202 -0.14073716 7.625 -0.1875 C8.33785156 -0.21263672 9.05070313 -0.23777344 9.78515625 -0.26367188 C14.60793941 -0.33004045 17.80896534 0.35768856 22 3 C24.73947331 6.49519008 25.4914457 9.62643298 26 14 C26.71285156 13.7834375 27.42570312 13.566875 28.16015625 13.34375 C40.06782007 9.88203347 47.93880204 11.09342674 58.796875 17.0234375 C68.37974425 22.46684863 76.2814452 27.06853349 79.40234375 38.14428711 C80.44223048 43.11305963 80.43251899 48.0040241 80.375 53.0625 C80.38660156 54.08666016 80.39820312 55.11082031 80.41015625 56.16601562 C80.37544988 65.21859385 78.90515045 73.10663293 72.55078125 79.8828125 C63.55630057 87.3483692 50.36370111 95.88392603 38.32421875 95.33203125 C30.68396833 94.2405669 24.24096536 90.12523861 17.75 86.1875 C16.73957642 85.58446045 16.73957642 85.58446045 15.70874023 84.96923828 C8.3488334 80.35143597 3.98395882 75.43838195 1.60623169 67.01853943 C0.41270864 61.07576798 0.51555398 55.06300215 0.48828125 49.0234375 C0.47140019 47.60281864 0.45324545 46.18221441 0.43388367 44.7616272 C0.38674865 41.0562599 0.35670504 37.3509519 0.33044434 33.64538574 C0.30045255 29.85071098 0.25398717 26.05623992 0.20898438 22.26171875 C0.12336767 14.84124775 0.05583952 7.42076124 0 0 Z M24 37 C19.45531538 43.48685754 16.98511662 48.86703043 18 57 C20.51960885 65.57711408 24.14027546 71.44135977 32 76 C37.65784587 78.51459816 43.51318974 79.1343309 49.4375 77.20703125 C53.28233515 75.55653458 56.82093236 73.76440665 60 71 C58.41934373 66.81126087 55.29340194 64.35065686 52 61.5 C48.5470958 58.51128339 46.093387 56.186774 44 52 C49.28 46.72 54.56 41.44 60 36 C54.2563836 30.2563836 51.00304059 28.4519562 43.125 27.5625 C35.62795343 27.88781563 28.66087691 31.14061189 24 37 Z '
        fill='#36ED84'
        transform='translate(3,6)'
      />
    </svg>
  )
}
