import ProfileCards from 'components/ProfileCards'
import ProfileFeed from 'components/ProfileFeed'
import ProfileRanks from 'components/ProfileRanks'
import ProfileShop from 'components/ProfileShop'
import Cards from 'pages/Cards'
import Farm from 'pages/Farm'
import Friends from 'pages/Friends'
import Games from 'pages/Games'
import NotFound from 'pages/NotFound'
import Profile from 'pages/Profile'
import Tasks from 'pages/Tasks'
import { PROFILE_ROUTES, ROUTES } from 'utils/constants'
import SpecialCards from 'pages/SpceialCards'

export const routes = [
  {
    component: Farm,
    path: ROUTES.home,
  },
  {
    component: Tasks,
    path: ROUTES.tasks,
  },
  {
    component: Cards,
    path: ROUTES.cards,
  },
  {
    component: Games,
    path: ROUTES.games,
  },
  {
    component: SpecialCards,
    path: ROUTES.special_cards,
  },
  {
    component: Profile,
    path: ROUTES.profile,
    children: [
      {
        component: ProfileFeed,
        path: '',
        index: true,
      },
      {
        component: ProfileRanks,
        path: PROFILE_ROUTES.ranks,
      },
      {
        component: Friends,
        path: PROFILE_ROUTES.friends,
      },
      {
        component: ProfileCards,
        path: PROFILE_ROUTES.cards,
      },
      {
        component: ProfileShop,
        path: PROFILE_ROUTES.shop,
      },
    ],
  },
  {
    component: NotFound,
    path: ROUTES.notFound,
  },
]
