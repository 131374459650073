import { Image } from 'assets/images'
import Button from 'common/Button'
import FormInput from 'common/FormInput'
import * as S from './styled'

function TonPopUpContent({ onSubmit, value, setValue }) {
  return (
    <S.TonPopUpContentWrapper>
      <S.StyledImage src={Image.tonPopUp} alt='daily lottery' />
      <FormInput
        width='100%'
        placeholder='Fill in your Toncoin wallet address'
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        text='Submit'
        isDisabled={!value}
        onClick={onSubmit}
        maxWidth='100%'
      />
    </S.TonPopUpContentWrapper>
  )
}

export default TonPopUpContent
