import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const ProfileShopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 32px;
  padding: 16px;
  ${overflowScroll}
`

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const UserImage = styled.img`
  max-width: 56px;
  max-height: 56px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PageTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.white};
`

export const StoreDescription = styled.p`
  color: ${COLORS.white};
  font-size: 12px;
  font-weight: 400;
`

export const StoreItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 100%;
`

export const StoreItem = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.black_28};
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
  font-family: Poppins;
`

export const StoreItemImageWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.grey_d1};
  padding: 4px;
  border-radius: 4px;
`
export const StoreItemImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`

export const StoreItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

export const StoreItemName = styled.p`
  font-size: 12px;
  color: ${COLORS.white};
`

export const StoreItemPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.grey_d1};
  border-radius: 4px;
  padding: 4px 6px;
  gap: 4px;
`

export const StoreItemPriceImage = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
`

export const StoreItemPrice = styled.p`
  font-size: 12px;
  color: ${COLORS.white};
`
