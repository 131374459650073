import { CloseIcon } from 'assets/icons/Close'
import { COUNTRY_IMAGES, Image } from 'assets/images'
import Button from 'common/Button'
import { GameCongratulationsDialog } from 'components/GameCongratulationsDialog'
import { GamesHeader } from 'components/GamesHeader'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { playPuzzleGame } from 'store/games/actions'
import { gamesSelector } from 'store/games/selectors'
import { COLORS, LOADING_STATUSES, ROUTES } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

export function PuzzleGame({ puzzle }) {
  const [answers, setAnswers] = useState({ 1: null, 2: null, 3: null, 4: null })
  const [openModal, setOpenModal] = useState(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { playPuzzleGameStatus, isPuzzleGameAnswerRight } =
    useSelector(gamesSelector)

  const selectedOption = (countryIndex) => {
    if (isPuzzleGameAnswerRight) {
      return
    }
    const newAnswers = { ...answers }
    for (let i = 1; i <= 4; i += 1) {
      if (newAnswers[i] === countryIndex) {
        newAnswers[i] = null
        break
      } else if (
        newAnswers[i] === null &&
        !Object.values(answers).includes(countryIndex)
      ) {
        newAnswers[i] = countryIndex
        break
      }
    }

    setAnswers(newAnswers)
  }

  const play = () => {
    dispatch(playPuzzleGame({ answer: Object.values(answers) }))
  }

  const isSubmitDisabled = useMemo(() => {
    return (
      playPuzzleGameStatus !== LOADING_STATUSES.idle ||
      Object.values(answers).some((answer) => answer === null)
    )
  }, [playPuzzleGameStatus, answers])

  const showWrongAnswer = useMemo(() => {
    return (
      playPuzzleGameStatus === LOADING_STATUSES.succeeded &&
      !isPuzzleGameAnswerRight
    )
  }, [playPuzzleGameStatus, isPuzzleGameAnswerRight])

  useEffect(() => {
    if (!puzzle.canPlay) {
      navigate(ROUTES.games)
    }
  }, [])

  return (
    <S.PuzzleView>
      <GamesHeader
        image={Image.flag}
        title='Flag puzzle'
        subtitle={`Earn ${formatCoin(puzzle.reward)} every day`}
        description={
          <>
            Find the correct combination of 4 icons. <br />
            You only have one try
          </>
        }
        imageSize='34px'
      />
      <S.PuzzleCountriesContainer>
        <S.PuzzleAnswersWrapper>
          {Object.entries(answers).map(([key, country]) => (
            <S.PuzzleOptionContainer key={key}>
              {showWrongAnswer ? (
                <CloseIcon
                  stroke={COLORS.blue}
                  style={{
                    stroke: COLORS.blue,
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                country && (
                  <S.PuzzleImage src={COUNTRY_IMAGES[country]} alt='img' />
                )
              )}
            </S.PuzzleOptionContainer>
          ))}
        </S.PuzzleAnswersWrapper>
        <S.PuzzleOptionsWrapper>
          {Object.entries(COUNTRY_IMAGES).map(([key, country]) => (
            <S.PuzzleOptionContainer
              key={key}
              active={Object.values(answers).includes(Number(key))}
              onClick={() => selectedOption(Number(key))}
            >
              <S.PuzzleImage src={country} alt='img' />
            </S.PuzzleOptionContainer>
          ))}
        </S.PuzzleOptionsWrapper>
      </S.PuzzleCountriesContainer>
      <Button
        text='Check'
        maxWidth='100%'
        maxHeight='46px'
        onClick={play}
        isDisabled={isSubmitDisabled}
      />
      <GameCongratulationsDialog
        closeDialog={() => setOpenModal(false)}
        reward={puzzle.reward}
        isOpen={
          playPuzzleGameStatus === LOADING_STATUSES.succeeded && openModal
        }
        success={isPuzzleGameAnswerRight}
      />
    </S.PuzzleView>
  )
}
