import { createSlice } from '@reduxjs/toolkit'
import {
  getLotteries,
  getInvoiceLink,
  getLatestLotteryTickets,
  getShopItems,
  getShopPurchases,
  getShopCards,
  getSpecialCards,
} from 'store/shop/actions'
import { LOADING_STATUSES } from 'utils/constants'

const initialState = {
  lotteries: [],
  shopItems: [],
  cards: [],
  specialCards: [],
  totalPurchases: null,
  boughtLotteries: null,
  latestInvoiceLink: null,
  getLotteriesStatus: LOADING_STATUSES.idle,
  getInvoiceLinkStatus: LOADING_STATUSES.idle,
  getLatestLotteryTicketsStatus: LOADING_STATUSES.idle,
  getShopItemsStatus: LOADING_STATUSES.idle,
  getShopPurchasesStatus: LOADING_STATUSES.idle,
  getShopCardsStatus: LOADING_STATUSES.idle,
  getSpecialCardsStatus: LOADING_STATUSES.idle,
}

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    resetBoughtLotteries: (state) => {
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.idle
      state.getInvoiceLinkStatus = LOADING_STATUSES.idle
      state.boughtLotteries = null
    },
    incrementTotalPurchases: (state) => {
      state.totalPurchases = (state.totalPurchases ?? 0) + 1
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getLotteries.pending, (state) => {
      state.getLotteriesStatus = LOADING_STATUSES.pending
    })
    addCase(getLotteries.fulfilled, (state, { payload }) => {
      state.lotteries = payload
      state.getLotteriesStatus = LOADING_STATUSES.succeeded
    })
    addCase(getLotteries.rejected, (state) => {
      state.getLotteriesStatus = LOADING_STATUSES.failed
    })

    addCase(getInvoiceLink.pending, (state) => {
      state.latestInvoiceLink = null
      state.getInvoiceLinkStatus = LOADING_STATUSES.pending
    })
    addCase(getInvoiceLink.fulfilled, (state, { payload }) => {
      state.latestInvoiceLink = payload
      state.getInvoiceLinkStatus = LOADING_STATUSES.succeeded
    })
    addCase(getInvoiceLink.rejected, (state) => {
      state.getInvoiceLinkStatus = LOADING_STATUSES.failed
    })

    addCase(getLatestLotteryTickets.pending, (state) => {
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.pending
    })
    addCase(getLatestLotteryTickets.fulfilled, (state, { payload }) => {
      state.boughtLotteries = payload
      state.lotteries = [...state.lotteries, ...payload]
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.succeeded
    })
    addCase(getLatestLotteryTickets.rejected, (state) => {
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.failed
    })

    addCase(getShopItems.pending, (state) => {
      state.getShopItemsStatus = LOADING_STATUSES.pending
    })
    addCase(getShopItems.fulfilled, (state, { payload }) => {
      state.shopItems = payload
      state.getShopItemsStatus = LOADING_STATUSES.succeeded
    })
    addCase(getShopItems.rejected, (state) => {
      state.getShopItemsStatus = LOADING_STATUSES.failed
    })

    addCase(getShopPurchases.pending, (state) => {
      state.getShopPurchasesStatus = LOADING_STATUSES.pending
    })
    addCase(getShopPurchases.fulfilled, (state, { payload }) => {
      state.totalPurchases = payload
      state.getShopPurchasesStatus = LOADING_STATUSES.succeeded
    })
    addCase(getShopPurchases.rejected, (state) => {
      state.getShopPurchasesStatus = LOADING_STATUSES.failed
    })

    addCase(getShopCards.pending, (state) => {
      state.getShopCardsStatus = LOADING_STATUSES.pending
    })
    addCase(getShopCards.fulfilled, (state, { payload }) => {
      state.cards = payload
      state.getShopCardsStatus = LOADING_STATUSES.succeeded
    })
    addCase(getShopCards.rejected, (state) => {
      state.getShopCardsStatus = LOADING_STATUSES.failed
    })

    addCase(getSpecialCards.pending, (state) => {
      state.getSpecialCardsStatus = LOADING_STATUSES.pending
    })
    addCase(getSpecialCards.fulfilled, (state, { payload }) => {
      state.specialCards = payload
      state.getSpecialCardsStatus = LOADING_STATUSES.succeeded
    })
    addCase(getSpecialCards.rejected, (state) => {
      state.getSpecialCardsStatus = LOADING_STATUSES.failed
    })
  },
})

export const shopReducer = shopSlice.reducer

export const shopActions = {
  getLotteries,
  getInvoiceLink,
  getLatestLotteryTickets,
  getShopItems,
  getShopPurchases,
  getShopCards,
  getSpecialCards,
  ...shopSlice.actions,
}
