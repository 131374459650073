import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const StyleInputWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
`

export const StyledInput = styled.input`
  height: 46px;
  border-radius: 6px;
  border: 2px solid ${COLORS.gray_64};
  background-color: transparent;
  width: 100%;
  padding: 0 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  caret-color: ${COLORS.grey_9e};
  color: ${COLORS.grey_c1};

  &:focus,
  &:focus-visible {
    outline: none;
    border-color: ${COLORS.grey_9e};
  }
`
