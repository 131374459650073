export const getFarmingLabel = (multiplier, autoFarming) => {
  switch (true) {
    case multiplier > 1 && autoFarming:
      return `${multiplier}x Auto-Farming`
    case autoFarming:
      return 'Auto-Farming'
    case multiplier > 1:
      return `${multiplier}x Farming`
    default:
      return 'Farming'
  }
}
