import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const PrizePopUpContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`

export const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
`

export const Text = styled.p`
  color: ${COLORS.white};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`
