import { Image } from 'assets/images'
import { Dialog } from 'common/Dialog'
import StatusesContent from 'components/DialogsContent/StatusesContent'
import LotteriesDialogContent from 'components/LotteriesDialogContent'
import ProfileFeedSection from 'components/ProfileFeedSectioon'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { friendsSelector } from 'store/friends/selectors'
import { profileSelector } from 'store/profile/selectors'
import { profileActions } from 'store/profile/slice'
import { shopSelector } from 'store/shop/selectors'
import { shopActions } from 'store/shop/slice'
import { userSelector } from 'store/user/selectors'
import { LOADING_STATUSES, ROUTES } from 'utils/constants'
import { formatNumberSuffix } from 'utils/format'
import { formatCoin } from 'utils/format-coin'
import { cardSelector } from 'store/card/selectors'
import * as S from './styled'

function ProfileFeed() {
  const { friendsCount } = useSelector(friendsSelector)
  const { user, userStatus, userTotalCoins } = useSelector(userSelector)
  const { lotteries, totalPurchases, getShopPurchasesStatus } =
    useSelector(shopSelector)
  const { totalCardsLength } = useSelector(cardSelector)

  const { userRank, getUserRankStatus } = useSelector(profileSelector)
  const [openStatusesDialog, setOpenStatusesDialog] = useState(false)
  const [openLotteriesDialog, setOpenLotteriesDialog] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const sections = [
    {
      image: Image.medal,
      title: 'Status',
      description: 'Current Status:',
      statisticIcon: userStatus.image,
      statistic: userStatus.title,
      onClick: () => setOpenStatusesDialog(true),
    },
    {
      image: Image.ranksCup,
      title: 'Ranks',
      statistic: `You‘re on the ${formatNumberSuffix(userRank)} place `,
      value: formatCoin(userTotalCoins),
      onClick: () => navigate(ROUTES.profile_ranks),
    },
    {
      image: Image.friends,
      title: 'Friends',
      description: 'Total:',
      statistic: friendsCount,
      onClick: () => navigate(ROUTES.profile_friends),
    },
    {
      image: Image.cards,
      title: 'Cards',
      description: 'Total:',
      statistic: `${totalCardsLength}/60`,
      onClick: () => navigate(ROUTES.profile_cards),
    },
    {
      image: Image.profileTicket,
      title: 'Lottery',
      description: 'Total:',
      statistic: lotteries.length,
      onClick: () => setOpenLotteriesDialog(true),
    },
    {
      image: Image.shop,
      title: 'Store',
      description: 'Total Purchases:',
      statistic: totalPurchases,
      onClick: () => navigate(ROUTES.profile_shop),
    },
  ]

  useEffect(() => {
    if (!userRank && getUserRankStatus === LOADING_STATUSES.idle) {
      dispatch(profileActions.getUserRank())
    }
    if (!totalPurchases && getShopPurchasesStatus === LOADING_STATUSES.idle) {
      dispatch(shopActions.getShopPurchases())
    }
  }, [])

  return (
    <S.ProfileFeedWrapper>
      <S.ProfileHeader>
        <S.UserImage src={Image.avatar} alt='avatar' />
        <S.UserInfo>
          {user.name}
          <S.UserStatus src={userStatus.image} alt='pilot' />
        </S.UserInfo>
      </S.ProfileHeader>
      <S.ProfileSectionsWrapper>
        {sections.map((section) => (
          <ProfileFeedSection section={section} key={section.title} />
        ))}
      </S.ProfileSectionsWrapper>
      <Dialog
        isOpen={openStatusesDialog}
        closeDialog={() => setOpenStatusesDialog(false)}
        title='Statuses'
      >
        <StatusesContent />
      </Dialog>
      <Dialog
        isOpen={openLotteriesDialog}
        closeDialog={() => setOpenLotteriesDialog(false)}
        title={lotteries?.length ? 'Lottery Tickets' : ''}
      >
        <LotteriesDialogContent lotteries={lotteries} />
      </Dialog>
    </S.ProfileFeedWrapper>
  )
}

export default ProfileFeed
