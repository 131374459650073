import { createAsyncThunk } from '@reduxjs/toolkit'
import { userApi } from './services'

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await userApi.registerUser(userData)
      localStorage.setItem('token', `Bearer ${data.token}`)
      return data.user
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getMe = createAsyncThunk(
  'user/getMe',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await userApi.getMe(body)
      localStorage.setItem('token', `Bearer ${data.token}`)
      return data.user
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchTestData = createAsyncThunk(
  'career/fetchTestData',
  async (item, { rejectWithValue }) => {
    try {
      const { data } = await userApi.getVacancies(item)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const registerReferral = createAsyncThunk(
  'user/registerReferral',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await userApi.registerReferral(userData)
      localStorage.setItem('token', `Bearer ${data.token}`)
      return data.user
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateDetails = createAsyncThunk(
  'user/updateDetails',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await userApi.updateDetails(userData)
      return data.user
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
