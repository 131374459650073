import * as S from './styled'

export function SpecialCardList({ cardsData }) {
  const specialCards = cardsData.filter((card) => card.special)

  return (
    <S.Grid>
      {specialCards.map((card) => (
        <S.CardContainer key={card._id}>
          <S.ContentWrapper>
            <S.ContentImageWrapper>
              <S.ContentImage src={card.imageUrl} />
            </S.ContentImageWrapper>
          </S.ContentWrapper>
          <S.TitleWrapper>{card.name}</S.TitleWrapper>
        </S.CardContainer>
      ))}
    </S.Grid>
  )
}
