import { SpecialCard } from 'assets/icons/SpecialCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { shopActions } from 'store/shop/slice'
import { shopSelector } from 'store/shop/selectors'
import { Cards } from './Cards'
import { SpecialCards } from './SpecialCards'
import { CardTabs } from '../ProfileCards/CardTabs'
import * as S from './styled'

export function SpecialCadsList() {
  const { cards, specialCards } = useSelector(shopSelector)

  const dispatch = useDispatch()

  const tabs = [
    {
      label: 'Cards',
      content: <Cards cardsData={cards} />,
    },
    {
      label: 'Special cards',
      content: <SpecialCards cardsData={specialCards} />,
    },
  ]

  useEffect(() => {
    dispatch(shopActions.getShopCards())
    dispatch(shopActions.getSpecialCards())
  }, [])

  return (
    <S.ProfileCardsWrapper>
      <S.ProfileHeader>
        <SpecialCard />
        <S.PageTitle>Buy Cards</S.PageTitle>
      </S.ProfileHeader>
      <CardTabs tabs={tabs} />
    </S.ProfileCardsWrapper>
  )
}
