import { GIF } from 'assets/gifs'
import Modal from 'common/Modal'
import * as S from './styled'

export function ShopCongratulationsModal({ onClose, isOpen, name }) {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <S.CongratulationsWrapper>
        <S.CongratulationsTop>
          <S.CongratulationImage
            src={GIF.chickenGlasses}
            alt='congratulations'
          />
          <S.CongratulationsTitle>Congratulations!</S.CongratulationsTitle>
        </S.CongratulationsTop>
        <S.ItemInfo>You successfully purchased {name}</S.ItemInfo>
      </S.CongratulationsWrapper>
    </Modal>
  )
}
