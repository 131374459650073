import { Image } from 'assets/images'
import { Dialog } from 'common/Dialog'
import BuyStoreItemDialogContent from 'components/BuyStoreItemDialog'
import { ShopCongratulationsModal } from 'components/ShopCongratulationsModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { farmActions } from 'store/farm/slice'
import { shopSelector } from 'store/shop/selectors'
import { shopActions } from 'store/shop/slice'
import { userActions } from 'store/user/slice'
import {
  LOADING_STATUSES,
  STORE_ITEM_IDS,
  STORE_ITEM_TYPES,
} from 'utils/constants'
import * as S from './styled'

function ProfileShop() {
  const { shopItems, getShopItemsStatus } = useSelector(shopSelector)

  const [openCongratulationsModal, setOpenCongratulationsModal] =
    useState(false)
  const [openBuyItemDialog, setOpenBuyItemDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const dispatch = useDispatch()

  const openBuyDialog = (item) => {
    setSelectedItem(item)
    setOpenBuyItemDialog(true)
  }

  const buyItem = () => {
    setOpenBuyItemDialog(false)
    window.Telegram.WebApp.openInvoice(selectedItem.invoiceLink)
  }

  const closeCongratulationsModal = () => {
    setOpenCongratulationsModal(false)
    setSelectedItem(null)
  }

  const handleSuccessfulPurchase = (item) => {
    setOpenCongratulationsModal(true)
    if (item.itemId.startsWith(STORE_ITEM_TYPES.erv)) {
      dispatch(userActions.addTotalCoins(item.count))
    }
    if (item.itemId.startsWith(STORE_ITEM_TYPES.farming)) {
      if (item.itemId !== STORE_ITEM_IDS.auto_farming) {
        dispatch(farmActions.setMultiplier(item.count))
      }
    }
  }

  const handleCloseBuyItemDialog = () => {
    setOpenBuyItemDialog(false)
  }

  useEffect(() => {
    if (!shopItems.length && getShopItemsStatus === LOADING_STATUSES.idle) {
      dispatch(shopActions.getShopItems())
    }

    const invoiceClosedHandler = (event) => {
      if (event && event.status === 'paid' && selectedItem) {
        handleSuccessfulPurchase(selectedItem)
        dispatch(shopActions.incrementTotalPurchases())
      } else {
        setSelectedItem(null)
      }
    }

    window.Telegram.WebApp.onEvent('invoiceClosed', invoiceClosedHandler)

    return () => {
      window.Telegram.WebApp.offEvent('invoiceClosed', invoiceClosedHandler)
    }
  }, [shopItems, getShopItemsStatus, selectedItem])

  return (
    <S.ProfileShopWrapper>
      <S.ProfileHeader>
        <S.UserImage src={Image.shop} alt='shop' />
        <S.PageTitle>Store</S.PageTitle>
        <S.StoreDescription>Exclusive offers just for you!</S.StoreDescription>
      </S.ProfileHeader>
      <S.StoreItemsWrapper>
        {shopItems?.map((item) => (
          <S.StoreItem key={item._id} onClick={() => openBuyDialog(item)}>
            <S.StoreItemImageWrapper>
              <S.StoreItemImage
                src={`${process.env.REACT_APP_API_URL}/images/${item.imagePath}`}
                alt='card'
              />
            </S.StoreItemImageWrapper>
            <S.StoreItemInfoWrapper>
              <S.StoreItemName>{item.name}</S.StoreItemName>
              <S.StoreItemPriceWrapper>
                <S.StoreItemPriceImage src={Image.telegramStar} alt='coin' />
                <S.StoreItemPrice>{item.price}</S.StoreItemPrice>
              </S.StoreItemPriceWrapper>
            </S.StoreItemInfoWrapper>
          </S.StoreItem>
        ))}
      </S.StoreItemsWrapper>
      <ShopCongratulationsModal
        isOpen={openCongratulationsModal}
        onClose={() => closeCongratulationsModal()}
        name={selectedItem?.name}
      />
      <Dialog
        title='Checkout'
        isOpen={openBuyItemDialog}
        closeDialog={handleCloseBuyItemDialog}
      >
        <BuyStoreItemDialogContent
          selectedItem={selectedItem}
          buyItem={buyItem}
        />
      </Dialog>
    </S.ProfileShopWrapper>
  )
}

export default ProfileShop
