import { useState } from 'react'
import * as S from './styled'

export function CardTabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <S.Wrapper>
      <S.CardTabs>
        {tabs.map((tab, index) => (
          <S.TabItem
            key={index}
            isActive={index === activeTab}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </S.TabItem>
        ))}
      </S.CardTabs>

      <S.Block>{tabs[activeTab] && tabs[activeTab].content}</S.Block>
    </S.Wrapper>
  )
}
