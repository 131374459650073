import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const CongratulationsWrapper = styled.div`
  padding-block: 28px;
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CongratulationsTop = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CongratulationImage = styled.img`
  width: 120px;

  @media (max-width: 480px) {
    width: 100px;
  }

  @media (max-width: 350px) {
    width: 80px;
  }
`

export const CongratulationsTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.blue};
`

export const ItemInfo = styled.p`
  text-align: center;
  color: ${COLORS.gray};
  font-size: 12px;
`
