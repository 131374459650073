import { Image } from 'assets/images'
import { Dialog } from 'common/Dialog'
import { useState } from 'react'
import * as S from './styled'
import BuyStoreItemDialogContent from '../BuyStoreItemDialog'

export function SpecialCards({ cardsData }) {
  const [openBuyItemDialog, setOpenBuyItemDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleCloseBuyItemDialog = () => {
    setOpenBuyItemDialog(false)
  }

  const openBuyDialog = (item) => {
    setSelectedItem(item)
    setOpenBuyItemDialog(true)
  }

  const buyItem = () => {
    setOpenBuyItemDialog(false)
    window.Telegram.WebApp.openInvoice(selectedItem.invoiceLink)
  }

  return (
    <S.StoreItemsWrapper>
      {cardsData.map((card) => (
        <S.StoreItem key={card.itemId} onClick={() => openBuyDialog(card)}>
          <S.StoreItemImageWrapper>
            <S.StoreItemImage
              src={`${process.env.REACT_APP_API_URL}/images/${card.imagePath}`}
              alt='card'
            />
          </S.StoreItemImageWrapper>
          <S.StoreItemInfoWrapper>
            <S.StoreItemName>{card.name}</S.StoreItemName>
            <S.StoreItemPriceWrapper>
              <S.StoreItemPriceImage src={Image.telegramStar} alt='coin' />
              <S.StoreItemPrice>{card.price}</S.StoreItemPrice>
            </S.StoreItemPriceWrapper>
          </S.StoreItemInfoWrapper>
        </S.StoreItem>
      ))}
      <Dialog
        title='Checkout'
        isOpen={openBuyItemDialog}
        closeDialog={handleCloseBuyItemDialog}
      >
        <BuyStoreItemDialogContent
          selectedItem={selectedItem}
          buyItem={buyItem}
        />
      </Dialog>
    </S.StoreItemsWrapper>
  )
}
