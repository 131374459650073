import { Image } from 'assets/images'

export const LOADING_STATUSES = {
  idle: 'IDLE',
  pending: 'PENDING',
  succeeded: 'SUCCEEDED',
  failed: 'FAILED',
}

export const ROUTES = {
  home: '/',
  tasks: '/tasks',
  cards: '/cards',
  friends: '/friends',
  games: '/games',
  profile: '/profile',
  profile_ranks: '/profile/ranks',
  profile_cards: '/profile/cards',
  profile_friends: '/profile/friends',
  profile_shop: '/profile/shop',
  special_cards: '/special-cards',
  notFound: '*',
}

export const PROFILE_ROUTES = {
  ranks: 'ranks',
  friends: 'friends',
  cards: 'cards',
  shop: 'shop',
}

export const COLORS = {
  black: '#0F0F0F',
  black_28: '#282828',
  gray_3B: '#3B3B3B80',
  dark_43: '#434343',
  gray: '#8F8F8F',
  gray_64: '#646464',
  gray_80: '#808080',
  gray_100: '#404145',
  grey_9e: '#9e9e9e',
  grey_c1: '#C1C1C1',
  grey_d1: '#1D1D1D',
  blue_white: '#E2E9F9',
  white: '#FFFFFF',
  white_f8: '#F8F8F8',
  blue: '#007AFF',
  blue_01: '#013479',
  blue_02: '#021B74',
  blue_03: '#021B7433',
  blue_00: '#007AFF',
  blue_002: '#002351',
  blue_004: '#004098',
  blue_op: '#296fe599',
  red: '#B3261E',
  green: '#4BB543',
}

export const TASK_SOURCES = {
  youtube: 'youtube',
  instagram: 'instagram',
  facebook: 'facebook',
  twitter: 'twitter',
  telegram: 'telegram',
  game: 'game',
  sponsor: 'sponsor',
}

export const TASK_SPONSORS = {
  okx: 'okx',
  stake: 'stake',
  pocketOption: 'pocketOption',
  olymptrade: 'olymptrade',
  bcgames: 'bcgames',
}

export const TASK_BUTTONS_STATES = {
  done: 'Done',
  start: 'Start',
  register: 'Register',
  play: 'Play',
  collect: 'Collect',
  check: 'Check',
  claim: 'Claim',
  share: 'Share',
  follow: 'Follow',
  retweet: 'Retweet',
}

export const TASK_TYPES = {
  all: 'all',
  once: 'invite',
  daily: 'daily',
}

export const DAILY_TASK_TYPES = {
  subscribe: 'subscribe',
  story: 'story',
}

export const GAME_TYPES = {
  quiz: 'quiz',
  puzzle: 'puzzle',
  roulette: 'roulette',
}

export const TASK_PRIORITIES = {
  high: 'high',
  medium: 'medium',
  low: 'low',
}

export const CONFIRMATION_TASKS = [
  TASK_SPONSORS.okx,
  TASK_SPONSORS.stake,
  TASK_SPONSORS.pocketOption,
  TASK_SPONSORS.olymptrade,
  TASK_SPONSORS.bcgames,
]

export const PRIZES = {
  1: 1000,
  2: 1500,
  3: 2000,
  4: 2500,
  5: 3000,
  6: 3500,
  7: 4000,
  8: 4500,
}

export const USER_STATUSES = {
  newCommerce: {
    title: 'New Commerce',
    image: Image.newCommerce,
    cards: '0',
  },
  tourist: {
    title: 'Tourist',
    image: Image.tourist,
    cards: '20+',
  },
  steward: {
    title: 'Steward',
    image: Image.steward,
    cards: '30+',
  },
  pilot: {
    title: 'Pilot',
    image: Image.pilot,
    cards: '40',
  },
}

export const LOTTERY_PRICE = 30

export const STORE_ITEM_TYPES = {
  cards: 'cards',
  farming: 'farming',
  erv: 'erv',
}

export const STORE_ITEM_IDS = {
  auto_farming: 'farming_auto',
}

export const HOURS_48_IN_MS = 48 * 60 * 60 * 1000
