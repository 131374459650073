import { Image } from 'assets/images'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'store/user/selectors'
import { useEffect } from 'react'
import { cardActions } from 'store/card/slice'
import { cardSelector } from 'store/card/selectors'
import * as S from './styled'
import { CardTabs } from './CardTabs'
import { CardList } from './CardList'
import { SpecialCardList } from './SpecialCardList'

function ProfileCards() {
  const { openedCards } = useSelector(userSelector)
  const { specialCards, boughtCards } = useSelector(cardSelector)

  const cardDetails = openedCards.map((item) => item.cardDetails)

  const mergedData = [...specialCards, ...boughtCards, ...cardDetails]

  const dispatch = useDispatch()

  const specialCardsLength = mergedData.filter((card) => card.special).length

  const cardsLength = mergedData.filter((card) => !card.special).length

  const tabs = [
    {
      label: 'Cards',
      content: <CardList cardsData={mergedData} />,
    },
    {
      label: 'Special cards',
      content: <SpecialCardList cardsData={mergedData} />,
    },
  ]

  useEffect(() => {
    dispatch(cardActions.getUsersCards())
  }, [])

  return (
    <S.ProfileCardsWrapper>
      <S.ProfileHeader>
        <S.UserImage src={Image.cards} alt='avatar' />
        <S.PageTitle>Cards</S.PageTitle>
        <S.UserInfo>
          <S.UserStatus>
            Cards: <S.Statistic>{cardsLength}/40</S.Statistic>
          </S.UserStatus>
          <S.UserStatus>
            Special cards: <S.Statistic>{specialCardsLength}/20</S.Statistic>
          </S.UserStatus>
        </S.UserInfo>
      </S.ProfileHeader>
      <CardTabs tabs={tabs} />
    </S.ProfileCardsWrapper>
  )
}

export default ProfileCards
