import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATUSES } from 'utils/constants'
import {
  getUsersCards,
  getUsersCardById,
  incrementAvailableCardsByOne,
} from 'store/card/actions'

const initialState = {
  cardsData: [],
  specialCards: [],
  boughtCards: [],
  totalCardsLength: 0,
  cardsTimeLeft: 0,
  availableCards: 0,
  totalCards: 0,
  cardStatus: LOADING_STATUSES.idle,
  openCardStatus: LOADING_STATUSES.idle,
  availableCardsStatus: LOADING_STATUSES.idle,
}

const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    incrementAvailableCards: (state, { payload }) => {
      state.availableCards += payload
    },
    setTotalCardsLength: (state, { payload }) => {
      state.totalCardsLength = payload
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getUsersCards.pending, (state) => {
      state.cardStatus = LOADING_STATUSES.pending
    })
    addCase(getUsersCards.fulfilled, (state, { payload }) => {
      if (payload) {
        state.boughtCards = payload.boughtCards
        state.specialCards = payload.specialCards
        state.cardsData = payload.cards
        state.cardsTimeLeft = payload.availableAt
        state.availableCards = payload.availableCards
        state.totalCards = payload.totalCards
        state.cardStatus = LOADING_STATUSES.succeeded
      } else {
        state.cardStatus = LOADING_STATUSES.idle
      }
    })
    addCase(getUsersCards.rejected, (state) => {
      state.cardStatus = LOADING_STATUSES.failed
    })
    addCase(getUsersCardById.pending, (state) => {
      state.openCardStatus = LOADING_STATUSES.pending
    })
    addCase(getUsersCardById.fulfilled, (state, { payload }) => {
      const { timeRemaining, cards } = payload
      state.availableCards -= 1
      state.totalCards += 1
      state.cardsTimeLeft = timeRemaining
      state.cardsData = cards
      state.openCardStatus = LOADING_STATUSES.succeeded
    })
    addCase(getUsersCardById.rejected, (state) => {
      state.openCardStatus = LOADING_STATUSES.failed
    })
    addCase(incrementAvailableCardsByOne.pending, (state) => {
      state.availableCardsStatus = LOADING_STATUSES.pending
    })
    addCase(incrementAvailableCardsByOne.fulfilled, (state) => {
      state.availableCardsStatus = LOADING_STATUSES.succeeded
      state.availableCards = 1
    })
    addCase(incrementAvailableCardsByOne.rejected, (state) => {
      state.availableCardsStatus = LOADING_STATUSES.failed
    })
  },
})

export const cardReducer = cardSlice.reducer

export const { incrementAvailableCards, setTotalCardsLength } =
  cardSlice.actions

export const cardActions = {
  getUsersCards,
  getUsersCardById,
  incrementAvailableCardsByOne,
  ...cardSlice.actions,
}
