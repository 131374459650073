export const API = {
  registerUser: '/users/register',
  getUserInfo: '/users/me',
  test: 'test',
  farm: '/farm',
  friends: 'users/friends',
  startFarming: '/farm/start',
  friendsCollect: '/users/friends-collect',
  registerReferral: '/users/referral',
  getCards: '/users/cards',
  getCardById: '/users/card-open',
  tasks: '/users/tasks',
  dailyTasks: '/daily-task',
  socialTasks: '/social-task',
  completeTask: '/users/task-complete',
  completeTasks: '/users/tasks-complete',
  completeDailyTask: '/daily-task/complete',
  completeSocialTask: '/social-task/complete',
  gainDailyTask: '/daily-task/gain',
  gainSocialTask: '/social-task/gain',
  incrementAvailableCardsByOne: '/users/cards-availability',
  getGames: '/games',
  playQuizGame: '/games/question',
  playPuzzleGame: '/games/puzzle',
  playRouletteGame: '/games/roulette',
  getUserLotteries: '/shop/lottery/tickets',
  getInvoice: '/shop/invoice',
  getLatestLotteryTicket: '/shop/lottery/latest',
  getTopRankedUsers: '/users/top-ranked',
  getUserRank: '/users/rank',
  getShopItems: '/shop',
  getShopPurchases: '/shop/purchases',
  getShopCards: '/shop/cards',
  getSpecialCards: '/shop/special-cards',
  updateDetails: '/users/details',
}
