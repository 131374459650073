import React from 'react'
import { CloseIcon } from 'assets/icons/Close'
import * as S from './styled'

export function Dialog({
  title,
  isOpen,
  closeDialog,
  children,
  top,
  hideCloseIcon,
}) {
  if (!isOpen) return null

  return (
    <S.DialogWrapper top={top}>
      <S.DialogContent top={top} hideCloseIcon={hideCloseIcon}>
        <S.DialogHeader>
          <S.HeaderLeftWrapper />
          <S.DialogTitle>{title}</S.DialogTitle>
          {hideCloseIcon ? null : <CloseIcon onClick={closeDialog} />}
        </S.DialogHeader>
        {children}
      </S.DialogContent>
    </S.DialogWrapper>
  )
}
