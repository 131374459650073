import { Image } from 'assets/images'
import * as S from './styled'

function PrizePopUpContent({ prizePopUpText }) {
  return (
    <S.PrizePopUpContentWrapper>
      <S.StyledImage src={Image.prizePopOp} alt='prizes' />
      <S.Text>{prizePopUpText} 🚀</S.Text>
    </S.PrizePopUpContentWrapper>
  )
}

export default PrizePopUpContent
