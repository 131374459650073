import styled from 'styled-components'

export const DailyRewardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`

export const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
`
