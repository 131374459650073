import { Image } from 'assets/images'
import Button from 'common/Button'
import * as S from './styled'

function DailyRewardsContent({ openLotteriesModal }) {
  return (
    <S.DailyRewardsWrapper>
      <S.StyledImage src={Image.dailyLotteryModal} alt='daily lottery' />
      <Button
        text='Join the Raffle'
        onClick={openLotteriesModal}
        maxWidth='100%'
      />
    </S.DailyRewardsWrapper>
  )
}

export default DailyRewardsContent
