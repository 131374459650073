import { expandViewport, retrieveLaunchParams } from '@telegram-apps/sdk-react'
import BottomMenu from 'components/BottomMenu'
import NavBar from 'components/NavBar'
import { OnboardingScreen } from 'components/OnboardingScreen'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Router } from 'routes/routes'
import { cardActions } from 'store/card/slice'
import { friendsActions } from 'store/friends/slice'
import { shopActions } from 'store/shop/slice'
import { userSelector } from 'store/user/selectors'
import { userActions } from 'store/user/slice'
import { LOADING_STATUSES, ROUTES } from 'utils/constants'
import { cardSelector } from './store/card/selectors'

function App() {
  const {
    getUserStatus,
    registerUserStatus,
    openedCards,
    updateDetailsStatus,
  } = useSelector(userSelector)
  const { specialCards, boughtCards, cardStatus } = useSelector(cardSelector)

  const cardDetails = openedCards.map((item) => item.cardDetails)
  const mergedData = [...specialCards, ...boughtCards, ...cardDetails]

  const [appIsReadyOpen, setIsAppReadyOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const dispatch = useDispatch()

  const ensureDocumentIsScrollable = () => {
    const isScrollable =
      document.documentElement.scrollHeight > window.innerHeight

    if (!isScrollable) {
      document.documentElement.style.setProperty('height', '100vh', 'important')
    }
  }

  const preventCollapse = () => {
    if (window.scrollY === 0) {
      window.scrollTo(0, 1)
    }
  }

  let user = {}
  const { initData } = retrieveLaunchParams()
  if (initData && initData.user) {
    user = initData.user
    expandViewport()
  }

  const registerUser = () => {
    const body = {
      telegramId: user.id,
      username: user.username ?? user.firstName,
    }

    if (searchParams.get('referralId')) {
      dispatch(
        userActions.registerReferral({
          ...body,
          referrerId: searchParams.get('referralId'),
        })
      )
      return
    }

    dispatch(userActions.registerUser(body))
  }

  useEffect(() => {
    dispatch(cardActions.getUsersCards())
    dispatch(userActions.getMe({ telegramId: user.id }))
    if (location.pathname !== ROUTES.cards) {
      dispatch(cardActions.getUsersCards())
    }
    dispatch(friendsActions.getFriends())
    dispatch(shopActions.getLotteries())
    ensureDocumentIsScrollable()
    const scrollableElement = document.querySelector('.scrollable-element')
    if (scrollableElement) {
      scrollableElement.addEventListener('touchstart', preventCollapse)
    }
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('touchstart', preventCollapse)
      }
    }
  }, [])

  useEffect(() => {
    if (
      getUserStatus === LOADING_STATUSES.succeeded ||
      registerUserStatus === LOADING_STATUSES.succeeded
    ) {
      setIsAppReadyOpen(true)
    } else if (
      getUserStatus === LOADING_STATUSES.failed &&
      registerUserStatus !== LOADING_STATUSES.failed
    ) {
      registerUser()
    }
  }, [getUserStatus, registerUserStatus])

  useEffect(() => {
    if (
      getUserStatus === LOADING_STATUSES.succeeded &&
      cardStatus === LOADING_STATUSES.succeeded
    ) {
      dispatch(cardActions.setTotalCardsLength(mergedData.length))
    }
  }, [getUserStatus, cardStatus])

  useEffect(() => {
    if (updateDetailsStatus === LOADING_STATUSES.succeeded) {
      dispatch(userActions.getMe({ telegramId: user.id }))
    }
  }, [updateDetailsStatus])

  const showNavigation = useCallback(
    () => location.pathname !== ROUTES.games || !location.search,
    [location.pathname, location.search]
  )

  return (
    <>
      <div className='app-container'>
        {appIsReadyOpen ? (
          <>
            {showNavigation() && <NavBar />}
            <Router />
            {showNavigation() && <BottomMenu />}
          </>
        ) : (
          <OnboardingScreen />
        )}
      </div>
      <ToastContainer />
    </>
  )
}

export default App
