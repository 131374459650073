import { Image } from 'assets/images/index'
import { Dialog } from 'common/Dialog/index'
import { GameListItem } from 'components/GameListItem/index'
import { GamesHeader } from 'components/GamesHeader'
import { GameTimeLeft } from 'components/GameTimeLeft/index'
import { PuzzleGame } from 'components/PuzzleGame'
import { QuizGame } from 'components/QuizGame/index'
import { RouletteGame } from 'components/RouletteGame'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { farmSelector } from 'store/farm/selectors'
import { GAME_TYPES, ROUTES } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import { getNextTimeAt16 } from 'utils/timeAt16'
import * as S from './styled'

export function GamesList({ quizGame, puzzleGame, rouletteGame }) {
  const { multiplier } = useSelector(farmSelector)

  const [searchParams, setSearchParams] = useSearchParams()
  const [openTomorrowModal, setOpenTomorrowModal] = useState(false)
  const [currentGame, setCurrentGame] = useState('')

  const navigate = useNavigate()

  const games = {
    quiz: {
      image: Image.millionaireCircle,
      game: GAME_TYPES.quiz,
      description: 'Who Wants to Be a Millionaire',
      reward: formatCoin(quizGame.reward ?? 0),
      canPlay: quizGame.canPlay,
    },
    puzzle: {
      image: Image.flag,
      game: GAME_TYPES.puzzle,
      description: 'Flag puzzle',
      reward: formatCoin(puzzleGame.reward ?? 0),
      canPlay: puzzleGame.canPlay,
    },
    roulette: {
      image: Image.roulette,
      game: GAME_TYPES.roulette,
      description: 'Spin the roulette',
      reward: `${formatCoin(1000 * multiplier)} - ${formatCoin(
        10000 * multiplier
      )}`,
      canPlay: rouletteGame,
    },
  }

  const onGameClick = (game, canPlay) => {
    if (canPlay) {
      setSearchParams({ game })
    } else {
      setOpenTomorrowModal(true)
    }
  }

  useEffect(() => {
    const gameParam = searchParams.get('game')
    if (gameParam) {
      window.Telegram.WebApp.BackButton.show()
    } else {
      window.Telegram.WebApp.BackButton.hide()
    }
    setCurrentGame(GAME_TYPES[gameParam])
  }, [searchParams])

  useEffect(() => {
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(ROUTES.games)
    })
  }, [])

  return (
    <S.GamesView isFullHeight={!!currentGame}>
      {!currentGame && (
        <S.GamesWrapper>
          <GamesHeader
            image={Image.gamepad}
            title='Games'
            subtitle='Collect rewards every day'
            imageSize='88px'
          />
          <S.GamesListWrapper>
            {Object.values(games).map((game) => (
              <GameListItem
                key={game.game}
                image={game.image}
                game={game.game}
                description={game.description}
                reward={game.reward}
                canPlay={game.canPlay}
                onGameClick={onGameClick}
              />
            ))}
          </S.GamesListWrapper>
        </S.GamesWrapper>
      )}
      {currentGame === GAME_TYPES.quiz && <QuizGame quiz={quizGame} />}
      {currentGame === GAME_TYPES.puzzle && <PuzzleGame puzzle={puzzleGame} />}
      {currentGame === GAME_TYPES.roulette && (
        <RouletteGame roulette={games.roulette} />
      )}
      <Dialog
        isOpen={openTomorrowModal}
        closeDialog={() => setOpenTomorrowModal(false)}
      >
        <GameTimeLeft targetDate={getNextTimeAt16()} />
      </Dialog>
    </S.GamesView>
  )
}
